import { SxStyleProp } from 'theme-ui';

export const LocationDetail: SxStyleProp = {
  m: 0,
  '&:last-of-type': { mt: '8px' },
  fontFamily: 'Roboto',
  fontSize: ['16px', '16px', '18px'],
  fontWeight: 500,
};

export const Card: SxStyleProp = {
  boxShadow: '0 0 10px 0 rgba(86, 86, 86, 0.32)',
  borderTop: 'solid 4px',
  borderColor: 'primary',
  px: ['20px', '36px'],
  pb: ['15px', '25px'],
  pt: ['16px', '26px'],
};

export const Wrapper: SxStyleProp = {
  fontSize: '20px',
  lineHeight: '1.2em',
  gap: '12px',
  flexDirection: 'row',
};


export const Heading: SxStyleProp = {
  textTransform: 'uppercase',
  fontSize: '24px',
  lineHeight: '1em',
  mb: '4px',
};

export const HoursLabel: SxStyleProp = {
  textTransform: 'uppercase',
  fontSize: '18px',
  lineHeight: '1em',
  mb: '4px',
  mt: '12px',
  fontWeight: 'bold',
};

export const HoursList: SxStyleProp = {
  listStyleType: 'none', 
  mb: '4px',
};

export const TelephoneWrapper: SxStyleProp = {
  cursor: 'pointer',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  mt: '12px',
  flexDirection: 'row',
  width: 'fit-content',
};

export const TelephoneLabelImage: SxStyleProp = {
  height: 20, 
  width: 20, 
};

export const TelephoneNumber: SxStyleProp = {
  color: 'primary',
  fontSize: '22px',
  fontWeight: 'bold',
};
