/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { map as _map, isArray as _isArray } from 'lodash';
import { BoxProps } from 'theme-ui';
import Box from '@lce/slice_v2/Layout/Box';
import Heading from '@lce/slice_v2/Elements/Heading';
import Image from '@lce/slice_v2/Elements/Image';
import Link from '@lce/slice_v2/Elements/Link';
import Text from '@lce/slice_v2/Elements/Text';
import { useUpdateMapId } from '@lce/intl-util/src/api/SelectedMapId/hooks/useUpdateMapId';
import ComponentListRenderer from '@lce/intl-ui/src/Modules/ComponentListRenderer';
import { Direction } from '@lce/intl-types/src/IDirection';

import { IOrderingModalProps } from '../../../../../../api/OrderingModal/useOrderingModal';
import LeavingSiteDirectionsModal from '../../../../../Patterns/Modals/LeavingSiteDirectionsModal/LeavingSiteDirectionsModal';
import LocationButtons from '../LocationButtons';
import { ILocationButtonsProps } from '../LocationButtons/LocationButtons';
import Phone from './assets/phone.png';
import * as Styled from './StoreLocation.style';

export interface IStoreLocationProps {
  locale: string;
  address: string | string[];
  name: string;
  telephoneNumber?: string;
  hours?: string | string[];
  latitude: number;
  longitude: number;
  pickupLink: string;
  deliveryOptions: IDeliveryOptionsProps[];
  directions: IDirectionsProps;
}

export interface IDirectionsProps {
  googleMapLink: string;
  locationName: string;
}

export interface IDeliveryOptionsProps {
  name: string;
  image: { url: string };
  url: string;
  buttonBorderColor?: { hex: string };
}

export interface ILocationListDefaultProps {
  pageTitle: string;
  hoursLabel: string;
  telephoneLabel: string;
  defaultHours: string | string[];
  isSorted: boolean;
  isSearchEnabled: boolean;
  searchLabel: string;
  pickupButtonLabel: string;
  deliveryButtonLabel: string;
  noPickupOrDeliveryText: string;
  isDirectionsModalOpen?: boolean;
}

const LocationDetail: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box {...props} sx={Styled.LocationDetail}>
    {children}
  </Box>
);

// eslint-disable-next-line max-lines-per-function
const StoreLocation: React.FC<IStoreLocationProps &
ILocationListDefaultProps & {
  modalContent: IOrderingModalProps;
  direction: Direction;
}> = ({
  name,
  address,
  telephoneNumber,
  hours,
  hoursLabel,
  latitude,
  longitude,
  defaultHours,
  pickupLink,
  deliveryOptions,
  modalContent,
  pickupButtonLabel,
  deliveryButtonLabel,
  direction: pageDirection,
  directions,
  noPickupOrDeliveryText,
  isDirectionsModalOpen = false,
}) => {
  const [setMapId, unsetMapId] = useUpdateMapId();
  const [isOpenDirections, setIsOpenDirections] = React.useState<boolean>(isDirectionsModalOpen);

  const locationButtonProps: ILocationButtonsProps = {
    pickupLink,
    pickupButtonLabel,
    deliveryOptions,
    deliveryButtonLabel,
    noPickupOrDeliveryText,
    modalContent,
  };

  const hasHours = (hours && hours.length > 0) || (defaultHours && defaultHours.length > 0);
  const key = `${latitude}${longitude}`;
  const hasDirections = directions?.googleMapLink;

  const handleDirectionsModal = () => {
    if (hasDirections) {
      setIsOpenDirections(true);
    }
  };

  return (
    <Box
      data-testid="storeLocation"
      onFocus={() => setMapId(key)}
      onMouseEnter={() => setMapId(key)}
      onMouseLeave={() => unsetMapId()}
      sx={Styled.Card}
    >
      <Box sx={Styled.Wrapper}>
        <Box onClick={handleDirectionsModal} sx={{ ':hover': { cursor: hasDirections ? 'pointer ' : 'default' } }}>
          <Heading as="h2" sx={Styled.Heading}>
            {name}
          </Heading>
          <ComponentListRenderer Component={LocationDetail} listText={address} />
        </Box>
        {hasHours && (
          <LocationDetail data-testid="locationHours">
            <Heading as="h2" sx={Styled.HoursLabel}>
              {hoursLabel}
            </Heading>
            <Box data-testid="locationHoursList" sx={Styled.HoursList}>
              {_map(_isArray(hours) && hours.length > 0 ? hours : defaultHours, (hour, index) => (
                <LocationDetail key={index}>{hour}</LocationDetail>
              ))}
            </Box>
          </LocationDetail>
        )}
        {telephoneNumber && (
          <Link href={`tel:${telephoneNumber}`} sx={Styled.TelephoneWrapper} variant="skeleton">
            <Image
              src={Phone}
              sx={{
                ...Styled.TelephoneLabelImage,
                ml: pageDirection === 'rtl' ? 2 : 0,
                mr: pageDirection === 'rtl' ? 0 : 2,
              }}
            />
            <Text sx={Styled.TelephoneNumber}>{telephoneNumber}</Text>
          </Link>
        )}
        <LocationButtons {...locationButtonProps} />
      </Box>
      <LeavingSiteDirectionsModal
        content={modalContent.directionsModal}
        directions={directions}
        isOpen={isOpenDirections}
        onClose={() => setIsOpenDirections(false)}
      />
    </Box>
  );
};

export default StoreLocation;
