/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { Divider } from '@lce/slice_v2';
import IconButton from '@lce/slice_v2/Elements/IconButton';
import Flex from '@lce/slice_v2/Layout/Flex';
import Link from '@lce/slice_v2/Elements/Link';
import Heading from '@lce/slice_v2/Elements/Heading';
import Text from '@lce/slice_v2/Elements/Text';
import Modal from '@lce/slice_v2/Patterns/Modal';
import { X } from 'react-feather';
import { SxStyleProp } from 'theme-ui';

import { IDirectionsProps } from '../../../PageComponents/ContactUs/StoreLocationsList/components/StoreLocation/StoreLocation';
import * as Styled from './LeavingSiteDirectionsModal.style';

export interface ILeavingSiteDirectionsModal {
  onClose: () => void;
  testId?: string;
  styledProps?: SxStyleProp;
  isOpen: boolean;
  directions: IDirectionsProps;
  content: ILeavingSiteModalContent;
}

export interface ILeavingSiteModalContent {
  title: string;
  confirmButtonText: string;
  cancelButtonText: string;
  bodyText: string;
  legalText: string;
}

const LeavingSiteDirectionsModal: React.FC<ILeavingSiteDirectionsModal> = ({
  directions,
  styledProps,
  content,
  testId,
  isOpen,
  onClose,
}) => (
  <Modal
    closeIcon={false}
    data-testid={`${testId ? `${testId}-` : ''}leavingSiteDirectionsModal`}
    isOpen={isOpen}
    lockBodyScroll={true}
    onClose={onClose}
    sx={Styled.Modal}
  >
    <Flex sx={Styled.CloseButtonWrapper}>
      <IconButton data-testid={`${testId ? `${testId}-` : ''}closeButton`} onClick={onClose} sx={{ cursor: 'pointer' }} variant="skeleton">
        <X color="black" size={32} />
      </IconButton>
    </Flex>
    <Flex sx={Styled.ContentWrapper}>
      <Heading sx={Styled.Title}>
        {content?.title}
      </Heading>
      <Divider
        data-testid="Modal__Divider"
        sx={ { width: '100%', my: [ 2, 4 ] } }
      />
      <Text sx={Styled.LegalText}>
        {content?.legalText}
      </Text>
      <Text sx={Styled.BodyText}>
        {content?.bodyText}
      </Text>
      <Flex sx={Styled.ButtonWrapper}>
        <Link
          data-testid={`${testId ? `${testId}-` : ''}link`}
          onClick={onClose}
          sx={styledProps || Styled.CancelButton}
          variant="skeleton"
        >
          {content?.cancelButtonText}
        </Link> 
        <Link
          data-testid={`${testId ? `${testId}-` : ''}link`}
          href={directions?.googleMapLink}
          onClick={onClose}
          sx={styledProps || Styled.ContinueButton}
          target="_blank"
          variant="skeleton"
        >
          {content?.confirmButtonText}
        </Link> 
      </Flex>
    </Flex>
  </Modal>
);

export default LeavingSiteDirectionsModal;
