import { SxStyleProp } from 'theme-ui';

export const ComingSoonText: SxStyleProp = {
  m: 0,
  fontFamily: 'Roboto',
  fontSize: ['16px', '16px', '18px'],
  fontWeight: 500,
};

export const ButtonWrapper: SxStyleProp = {
  display: 'grid',
  gridTemplateColumns: '1fr minmax(auto, 30px) 1fr',
  alignItems: 'center',
  mt: '12px',
};

export const ButtonWrapperText: SxStyleProp = {
  ...ButtonWrapper,
  gridTemplateColumns: '1fr',
};

const BasicButton: SxStyleProp = {
  cursor: 'pointer',
  borderRadius: 0,
  alignItems: 'center',
  py: '12px',
  px: '5px',
  width: '100%',
  textAlign: 'center',
  textDecoration: 'none',
};

const BasicTextButton: SxStyleProp = {
  ...BasicButton,
  fontFamily: 'primary',
  fontSize: ['16px', '16px', '20px'],
  textTransform: 'uppercase',
  lineHeight: 1,
  fontWeight: 'bold',
};

export const PickupButton: SxStyleProp = {
  ...BasicTextButton,
  color: 'white',
  backgroundColor: 'primary',
};

export const DeliveryOptionsButton: SxStyleProp = {
  ...BasicTextButton,
  color: 'primary',
  borderColor: 'primary',
  border: 'solid 1px',
  backgroundColor: 'transparent',
};

export const DeliveryLogoButton: SxStyleProp = {
  ...BasicButton,
  lineHeight: 0,
  border: 'solid 1px',
  background: 'transparent',
};

export const DeliveryLogoModalButton: SxStyleProp = {
  ...BasicButton,
  mx: 'auto',
  px: '20px',
  width: 'auto',
  lineHeight: 0,
  border: 'solid 1px',
  background: 'transparent',
};

export const Spear: SxStyleProp = {
  height: [50, 54],
  width: 5,
  mx: 'auto',
};

export const DeliveryLogo: SxStyleProp = {
  height: ['14px', '14px', '18px'],
  objectFit: 'contain',
};
