import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';
import { Map, MarkerClusterer } from '@lce/intl-map/src';
import MarkerLoader, { IMapDisplayProps } from '@lce/intl-ui/src/PageComponents/ContactUs/Map/components/MarkerLoader';

export interface ILocationProps {
  latitude: number;
  longitude: number;
  name: string;
  address: string[];
  telephoneNumber: string;
  telephoneLabel: string;
}

interface IMapProps extends IMapDisplayProps {
  apiKey: string;
  showSearchBar: boolean;
}

const DefaultMap: React.FC<IMapProps> = ({ apiKey, showSearchBar, ...props }) => {
  const positionDefaults = {
    centerDefault: props.center ? props.center : { lat: 0, lng: 0 },
    zoomDefault: props.zoom ? props.zoom : 16,
  };

  const mapsConfig = {
    apiKey,
    defaultOptions: {
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
    },
  };

  return (
    <Map { ...mapsConfig } { ...positionDefaults } containerElement={ 
      <Box 
        className="StoreLocationMap" 
        sx={{ 
          height: ['300px', '300px', '624px'], 
          gridArea: 'map', 
          gridRowStart: showSearchBar ? 'map' : 'label',
        }} 
      /> 
    }>
      <MarkerClusterer>
        <MarkerLoader locations={ props.locations } markerImage={ props.markerImage } />
      </MarkerClusterer>
    </Map>
  );
};

export default DefaultMap;
