import { SxStyleProp } from 'theme-ui';

export const Modal: SxStyleProp = {
  maxWidth: '700px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: ['90%', '100%', '100%'],
  height: ['95%', 'auto', 'auto'], 
  my: '0 !important',
  overflow: 'auto !important',
};

export const CloseButtonWrapper: SxStyleProp = { 
  justifyContent: 'flex-end', 
};

export const ContentWrapper: SxStyleProp = {
  flexDirection: 'column',
  padding: ['15px', '50px'],
  textAlign: 'center',
  gap: '20px',
};

export const Title: SxStyleProp = {
  textTransform: 'uppercase',
  fontSize: ['20px', '20px', '24px'],
  lineHeight: '1',
};

export const Subtitle: SxStyleProp = {
  fontSize: ['16px', '16px', '20px'],
  textAlign: 'center',
  lineHeight: '1',
  fontFamily: 'tertiary',
};

export const DeliveryButtonsWrapper: SxStyleProp = {
  display: 'grid', 
  mx: 'auto', 
  gridTemplateColumns: ['1fr', 'repeat(4, 1fr)'],
  gap: '20px',
};

export const DeliveryButton: SxStyleProp = {
  borderRadius: 0,
  border: 'solid 1px',
  background: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  p: '20px 26px',
  width: '100%',
};

export const DeliveryLogo: SxStyleProp = {
  height: '30px', 
  objectFit: 'contain',
};

export const LegalText: SxStyleProp = {
  fontSize: '12px',
  lineHeight: '1.2',
  textAlign: 'center',
  m: '16px auto',
  fontFamily: 'tertiary',
  color: '#4e4f51',
};

export const PickupButton: SxStyleProp = {
  alignItems: 'center',
  py: '12px',            
  px: '5px',
  color: 'white',
  backgroundColor: 'primary',
  fontFamily: 'primary',
  textAlign: 'center',
  textDecoration: 'none',
  fontSize: ['16px', '16px', '20px'],
  textTransform: 'uppercase',
  lineHeight: 1,
  width: '100%',
  fontWeight: 'bold',
};

export const CancelButton: SxStyleProp = {
  color: 'black', 
  fontSize: '16px', 
  fontFamily: 'tertiary',
  cursor: 'pointer',
};

