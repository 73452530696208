/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { map as _map } from 'lodash';
import { X } from 'react-feather';
import Button from '@lce/slice_v2/Elements/Button';
import Box from '@lce/slice_v2/Layout/Box';
import IconButton from '@lce/slice_v2/Elements/IconButton';
import Flex from '@lce/slice_v2/Layout/Flex';
import Link from '@lce/slice_v2/Elements/Link';
import Heading from '@lce/slice_v2/Elements/Heading';
import Image from '@lce/slice_v2/Elements/Image';
import Text from '@lce/slice_v2/Elements/Text';
import Modal from '@lce/slice_v2/Patterns/Modal';

import { IDeliveryOptionsProps } from '../../../PageComponents/ContactUs/StoreLocationsList/components/StoreLocation';
import { IDeliveryModalProps } from '../../../../api/OrderingModal/useOrderingModal';
import * as Styled from './DeliveryOptionsModal.style';

export interface IDeliveryOptionsModalProps {
  onClose: () => void;
  pickupButtonUrl: string;
  deliveryOptions: IDeliveryOptionsProps[];
  content?: IDeliveryModalProps;
  isOpen: boolean;
}

const DeliveryOptionsModal: React.FC<IDeliveryOptionsModalProps> = ({
  onClose,
  pickupButtonUrl,
  deliveryOptions,
  content,
  isOpen,
}) => (
  <Modal closeIcon={false} data-testid="deliveryOptionsModal" isOpen={isOpen} onClose={onClose} sx={Styled.Modal}>
    <Flex sx={Styled.CloseButtonWrapper}>
      <IconButton
        data-testid="deliveryOptions-closeButton"
        onClick={onClose}
        sx={{ cursor: 'pointer' }}
        variant="skeleton"
      >
        <X color="black" size={32} />
      </IconButton>
    </Flex>
    <Flex sx={Styled.ContentWrapper}>
      <Heading sx={Styled.Title}>{content?.title}</Heading>
      <Text sx={Styled.Subtitle}>{content?.selectDeliveryOptionLabel}</Text>
      <Box sx={Styled.DeliveryButtonsWrapper}>
        {_map(deliveryOptions, (deliveryOption, index) => {
          const oddNumberOfItems = deliveryOptions.length % 2 !== 0;
          const isLastItem = index === deliveryOptions.length - 1;
          const shouldCenterItem = oddNumberOfItems && isLastItem;

          return (
            <Link
              data-testid={`${deliveryOption.name}-link`}
              href={deliveryOption.url}
              key={index}
              sx={{
                ...Styled.DeliveryButton,
                gridColumn: shouldCenterItem ? ['unset', '2 / span 2'] : ['unset', 'span 2'],
                borderColor: deliveryOption.buttonBorderColor?.hex || 'primary',
              }}
              target="_blank"
              variant="skeleton"
            >
              <Image src={deliveryOption.image.url} sx={Styled.DeliveryLogo} />
            </Link>
          );
        })}
      </Box>
      <Text sx={Styled.LegalText}>{content?.legalText}</Text>
      {pickupButtonUrl && (
        <>
          <Link
            data-testid="pickupButton"
            href={pickupButtonUrl}
            sx={Styled.PickupButton}
            target="_blank"
            variant="skeleton"
          >
            {content?.pickupButtonLabel}
          </Link>
          <Button data-testid="cancelButton" onClick={onClose} sx={Styled.CancelButton} variant="skeleton">
            {content?.cancelButtonLabel}
          </Button>
        </>
      )}
    </Flex>
  </Modal>
);

export default DeliveryOptionsModal;
