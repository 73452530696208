

/* eslint-disable max-lines-per-function */
import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';
import Button from '@lce/slice_v2/Elements/Button';
import Form from '@lce/slice_v2/Elements/Form';
import { XCircle } from 'react-feather';

import { Direction } from '@lce/intl-types/src/IDirection';
import { ILocationProps } from '../Map';
import { IStoreLocationProps } from '../StoreLocationsList/components/StoreLocation';
import * as Styled from './LocationsSearchBar.style';


export interface ILocationsSearchBar {
  direction: Direction;
  searchLabel: string;
  setFilteredLocations: React.Dispatch<React.SetStateAction<IStoreLocationProps[]>>;
  setFilteredMapLocations: React.Dispatch<React.SetStateAction<ILocationProps[]>>;
  locations: IStoreLocationProps[];
  mapLocations: ILocationProps[];
}

const locationsFilter = (
  searchValue: string,
  setFilteredLocations: React.Dispatch<React.SetStateAction<IStoreLocationProps[]>>,
  setFilteredMapLocations: React.Dispatch<React.SetStateAction<ILocationProps[]>>,
  locations: IStoreLocationProps[], 
  mapLocations: ILocationProps[]
) => {
  if (searchValue.length > 0) {
    const result = locations.filter(
      location => location.address.toString().trim().toLowerCase().includes(searchValue.trim().toLowerCase()));
    const mapResult = mapLocations.filter(
      mapLocation => mapLocation.address.toString().trim().toLowerCase().includes(searchValue.trim().toLowerCase()));
    setFilteredLocations(result);
    setFilteredMapLocations(mapResult);
  } else {
    setFilteredLocations(locations);
    setFilteredMapLocations(mapLocations);
  }
};

interface IClearButton {
  onClick: () => void;
  direction: Direction;
}

const ClearButton: React.FC<IClearButton> = ({ onClick,direction })  => (
  <Button 
    data-testid="clearTextButton"
    onClick={onClick}
    sx={direction === 'ltr' ? Styled.SearchBoxButtonLeft : Styled.SearchBoxButtonRight}
    variant="skeleton"
  >
    <XCircle color="#767679" size="20px" />
  </Button>
);

const LocationsSearchBar: React.FC<ILocationsSearchBar> = ({ 
  setFilteredLocations, 
  setFilteredMapLocations, 
  searchLabel, 
  locations, 
  mapLocations,
  direction,
}) => {
  const [ searchValue, setSearchValue ] = React.useState('');
  const clearOnClick = () => {
    setSearchValue('');
    locationsFilter(
      '', 
      setFilteredLocations, 
      setFilteredMapLocations, 
      locations,  
      mapLocations
    );
  };

  return (
    <>
      <Form.Label id="postal-code-label" sx={Styled.FormLabel}>
        {searchLabel}
      </Form.Label>
      <Box data-testid="SearchLocations" sx={Styled.SearchBoxWrapper}>
        { direction === 'rtl' &&
          <ClearButton direction={direction} onClick={clearOnClick} /> 
        }
        <Form.Input.Text
          aria-labelledby="postal-code-label"
          id="postal-code-search"
          name="postal-code-search"
          onChange={ (e: React.ChangeEvent<HTMLInputElement>) => { 
            e.preventDefault();
            setSearchValue(e.target.value);
          }}
          onKeyPress={ (e: React.KeyboardEvent<Element>) => {
            if( e.key === 'Enter' ) {
              locationsFilter(
                searchValue, 
                setFilteredLocations, 
                setFilteredMapLocations, 
                locations,  
                mapLocations
              );
            }
          }}
          sx={Styled.SearchBoxForm}
          value={searchValue}
        />
        { direction === 'ltr' &&
          <ClearButton direction={direction} onClick={clearOnClick} /> 
        }
      </Box>
    </>  
  );
};

export default LocationsSearchBar;
