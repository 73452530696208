/* eslint-disable complexity */
import * as React from 'react';
import { Box, Button, Image, Text } from '@lce/slice_v2';

import { IDeliveryOptionsProps } from '../StoreLocation';
import DeliveryOptionsModal from '../../../../../Patterns/Modals/DeliveryOptionsModal';
import LeavingSiteModal from '../../../../../Patterns/Modals/LeavingSiteModal';
import { IOrderingModalProps } from '../../../../../../api/OrderingModal/useOrderingModal';

import * as Styled from './LocationButtons.style';
import Spear from './assets/spear-divider.png';

export interface ILocationButtonsProps {
  pickupLink: string;
  pickupButtonLabel: string;
  deliveryOptions: IDeliveryOptionsProps[];
  noPickupOrDeliveryText: string;
  deliveryButtonLabel: string;
  modalContent: IOrderingModalProps;
}

export interface ILocationButtonDefaultProps {
  isDeliveryOptionsModalOpen?: boolean;
  isDeliveryModalOpen?: boolean;
  isPickupModalOpen?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const LocationButtons: React.FC<ILocationButtonsProps & ILocationButtonDefaultProps> = ({
  pickupLink,
  pickupButtonLabel,
  deliveryOptions,
  deliveryButtonLabel,
  noPickupOrDeliveryText,
  modalContent,
  isDeliveryOptionsModalOpen = false,
  isDeliveryModalOpen = false,
  isPickupModalOpen = false,
}) => {
  const [isOpenDeliveryOptions, setIsOpenDeliveryOptions] = React.useState<boolean>(isDeliveryOptionsModalOpen);
  const [isOpenDelivery, setIsOpenDelivery] = React.useState<boolean>(isDeliveryModalOpen);
  const [isOpenPickup, setIsOpenPickup] = React.useState<boolean>(isPickupModalOpen);

  const showPickupButton = pickupLink && pickupButtonLabel;
  const showDeliveryButton = deliveryOptions?.length === 1 && deliveryOptions?.[0]?.image.url;
  const showDeliveryOptionsButton = deliveryOptions?.length > 1 && deliveryButtonLabel;
  const hasDeliveryButton = showDeliveryButton || showDeliveryOptionsButton;
  const hasNoOrdering = !showPickupButton && !hasDeliveryButton;

  if (hasNoOrdering) {
    return (
      <Box data-testid="location-buttons-component" sx={Styled.ButtonWrapperText}>
        <Text sx={Styled.ComingSoonText}>{noPickupOrDeliveryText}</Text>
      </Box>
    );
  }

  return (
    <>
      <Box data-testid="location-buttons-component" sx={Styled.ButtonWrapper}>
        {showPickupButton && (
          <Button
            data-testid="pickupLink"
            onClick={() => setIsOpenPickup(true)}
            sx={Styled.PickupButton}
            variant="skeleton"
          >
            {pickupButtonLabel}
          </Button>
        )}
        {showPickupButton && hasDeliveryButton && <Image src={Spear} sx={Styled.Spear} />}
        {showDeliveryOptionsButton && (
          <Button
            data-testid="deliveryOptionsButton"
            onClick={() => setIsOpenDeliveryOptions(true)}
            sx={Styled.DeliveryOptionsButton}
            variant="skeleton"
          >
            {deliveryButtonLabel}
          </Button>
        )}
        {showDeliveryButton && (
          <Button
            data-testid="deliveryLink"
            onClick={() => setIsOpenDelivery(true)}
            sx={{
              ...Styled.DeliveryLogoButton,
              borderColor: deliveryOptions?.[0]?.buttonBorderColor?.hex || 'primary',
            }}
            variant="skeleton"
          >
            <Image src={deliveryOptions?.[0]?.image?.url} sx={Styled.DeliveryLogo} />
          </Button>
        )}
      </Box>

      <DeliveryOptionsModal
        content={modalContent.deliveryOptionsModal}
        deliveryOptions={deliveryOptions}
        isOpen={isOpenDeliveryOptions}
        onClose={() => setIsOpenDeliveryOptions(false)}
        pickupButtonUrl={pickupLink}
      />
      <LeavingSiteModal
        content={modalContent.pickupModal}
        isOpen={isOpenPickup}
        onClose={() => setIsOpenPickup(false)}
        testId="pickup"
        url={pickupLink}
      >
        {pickupButtonLabel}
      </LeavingSiteModal>
      <LeavingSiteModal
        content={modalContent.deliveryModal}
        isOpen={isOpenDelivery}
        onClose={() => setIsOpenDelivery(false)}
        styledProps={{
          ...Styled.DeliveryLogoModalButton,
          borderColor: deliveryOptions?.[0]?.buttonBorderColor?.hex || 'primary',
        }}
        testId="delivery"
        url={deliveryOptions?.[0]?.url}
      >
        <Image data-testid="image-button" src={deliveryOptions?.[0]?.image?.url} sx={Styled.DeliveryLogo} />
      </LeavingSiteModal>
    </>
  );
};

export default LocationButtons;
